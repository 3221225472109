import React from 'react';

import Image from 'next/image';

import invitationRegsiterH5 from '@images/invitationImages/invitationRegsiterH5.webp';
import { useClient } from '@lib/hooks';
import { I18n } from '@utils/index';

import styles from './index.module.less';

export const InvitationRebate: React.FC<any> = () => {
  const isPC = useClient();
  return (
    <div className={styles.container}>
      <div className={styles.title}>{I18n.t('NFJEa2cb')}</div>
      <div className={styles.infoBox}>
        <span className={styles.msg}>{I18n.t('QV7ZVgz4')}</span>
      </div>
      <div className={styles.infoBox}>
        <span className={styles.msg}>{I18n.t('DL3iRU5q')}</span>
      </div>
      <div className={styles.imgBox}>
        <Image
        src={invitationRegsiterH5}
          width={isPC ? 415 : 345}
          height={isPC ? 325 : 270}

          alt='kikitrade'
        />
      </div>
    </div>
  );
};
