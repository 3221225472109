import React from 'react'

import { DefaultLeftSide } from './DefaultLeftSide'
import { InvitationRebate } from './InvitationRebate'

interface LeftSideProps {
  type?: 'invitationRebate'
}

export const LeftSide: React.FC<LeftSideProps> = (props) => {
  const { type = 'defaultComponent' } = props
  const sideMap = {
    defaultComponent: DefaultLeftSide,
    invitationRebate: InvitationRebate
  }

  const C = sideMap[type] || DefaultLeftSide

  return <C />
}
